// Imports
import plugin0 from '/Users/spencercorwin/neo-one/common/temp/node_modules/react-static-plugin-reach-router/browser.api.js'

// Plugins
const plugins = [{
        location: "/Users/spencercorwin/neo-one/packages/neo-one-build-tools-web/dist/webpack/plugin",
        plugins: [],
        hooks: {}
      },
{
        location: "/Users/spencercorwin/neo-one/common/temp/node_modules/react-static-plugin-emotion",
        plugins: [],
        hooks: {}
      },
{
        location: "/Users/spencercorwin/neo-one/common/temp/node_modules/react-static-plugin-reach-router",
        plugins: [],
        hooks: plugin0({})
      },
{
        location: "/Users/spencercorwin/neo-one/common/temp/node_modules/react-static-plugin-source-filesystem",
        plugins: [],
        hooks: {}
      },
{
        location: "/Users/spencercorwin/neo-one/packages/neo-one-website/src",
        plugins: [],
        hooks: {}
      }]

// Export em!
export default plugins